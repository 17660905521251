import "../download-older-version/style.scss";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import BreadcrumbsBlock from "../../../components/Breadcrumbs/BreadcrumbsBlock";
import Container from "../../../components/Container";
import EmailForm from "../../../components/Forms/_email";
import Layout from "../../../layouts";
import { createClassName } from "../../../helpers";
import supportCom from "../../../components/ForMarkDownComponents/_support";

const cn = createClassName("RemainderAndDownload");

const KeyReminder = (data) => {
  const { t } = useTranslation("forms");
  const { htmlAst, frontmatter } = data.data.markdownRemark;

  return (
    <Layout {...frontmatter.seo[0]}>
      <section aria-label="License key reminder">
        <BreadcrumbsBlock frontmatter={frontmatter} />
        <Container className={cn("wrap")}>
          {supportCom(htmlAst)}
          <EmailForm
            apiLink="licenses/remind"
            classNameForm={cn("form", { wrap: true })}
            fieldClassName={cn("form", { field: true })}
            buttonTextKey="my_license_key"
            tooltipContent={t("forms:tooltip.key-reminder")}
            errorHeaderTitle={t("keyReminder.errorHeaderTitle")}
            successHeaderTitle={t("keyReminder.successHeaderTitle")}
            tryAgain
          />
        </Container>
      </section>
    </Layout>
  );
};

export default KeyReminder;

export const query = graphql`
  query getKeyReminder($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
      }
    }
  }
`;
